import React from "react";
import CoreCarousel from "./../CoreCarousel";
import { ImagesCarouselDiv } from "./elements";

const ImagesCarousel = ({ images }) => {
  const [currentSlide, setCurrentSlide] = React.useState(
    images && images.length > 1 ? 1 : 0
  );

  return (
    <ImagesCarouselDiv>
      <CoreCarousel
        images={images}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
        controlColor={"var(--purple)"}
        shadow={true}
        imagesAreUrls
      />
    </ImagesCarouselDiv>
  );
};
export default ImagesCarousel;
