import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { SlideWrapper } from "./elements";
// import { size } from "polished";

const CoreCarousel = ({
  images,
  currentSlide,
  setCurrentSlide,
  controlColor,
  shadow,
  imagesAreUrls,
}) => {
  // console.log(images);
  const slideClass = (index) => {
    if (currentSlide === index) {
      return "on";
    }
    if (currentSlide - index === 1) {
      return "left";
    }
    if (index - currentSlide === 1) {
      return "right";
    }
    return "hidden";
  };
  // console.log(images);
  return (
    <SlideWrapper controlColor={controlColor || "var(--white)"} shadow={shadow}>
      {images && images.length ? (
        <React.Fragment>
          <div>
            <div>
              {images.map((image, index) => (
                <div key={index} className={slideClass(index)}>
                  <a
                    href={
                      index === currentSlide && image.link ? image.link : "/#"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentSlide(index);
                    }}
                  >
                    {imagesAreUrls ? (
                      <Img fixed={image.fixed} />
                    ) : (
                      <div>{image.image}</div>
                    )}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <nav>
            <ul>
              {images.length > 1
                ? images.map((x, index) => (
                    <li
                      className={currentSlide === index ? "selected" : ""}
                      key={`carousel${index}`}
                    >
                      <a
                        aria-hidden
                        href="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentSlide(index);
                        }}
                      >
                        {index}
                      </a>
                    </li>
                  ))
                : null}
            </ul>
          </nav>
        </React.Fragment>
      ) : null}
    </SlideWrapper>
  );
};

export default CoreCarousel;

CoreCarousel.propTypes = {
  /** These two should be from React.useState in parent component. This is the index of the selected slide. */
  currentSlide: PropTypes.number,
  /** These two should be from React.useState in parent component. This returns an index of a slide to turn to. */
  setCurrentSlide: PropTypes.func,
  /** This is for the color of the controls at the bottom: default is white.
   * */
  controlColor: PropTypes.string,
  /** This is for whether the selected image should have a drop shadow or not */
  shadow: PropTypes.bool,
  /** This is currently set up to take DOM nodes, though we could modify it to just be images + alt text */
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.element,
      link: PropTypes.string,
    })
  ),
};

CoreCarousel.defaultProps = {
  currentSlide: 1,
  controlColor: "var(--white)",
  shadow: true,
};
