import styled from "styled-components";

export const PhotoEssayDescriptionBlockDiv = styled.div`
  color: var(--white);
  max-width: calc(calc(var(--width) - var(--outerMargin)) - var(--outerMargin));
  width: 100%;
  margin: 25px auto;
  & h5 {
    margin-bottom: 35px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    text-align: center;
    padding: 0 10px;
    & h3 {
      font-size: 18px;
      line-height: 1.5;
      font-weight: 400;
    }
  }
`;
