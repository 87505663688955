import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "./../components/Layout";
import ArticleBody from "./../components/ArticleBody";
import ArticleFilter from "./../components/ArticleFilter";
import ArticleHeroImage from "./../components/ArticleHeroImage";
import PhotoEssayDescriptionBlock from "./../components/PhotoEssayDescriptionBlock";
import { ImageBody } from "./../components/VideoBody";
import { PhotoEssayWrapper } from "./../components/ArticleBody/elements.js";
import { getDate } from "./../modules/time";

const ArticleImagesPageTemplate = ({ data, pageContext }) => {
  const {
    title,
    headerImage,
    headerImageMobile,
    richContent,
    editorialAuthor,
    editorialShortDescription,
    editorialDate,
    editorialIsPhotoEssay,
    editorialPhotoEssayAuthor,
    editorialPhotoEssayDescription,
    subtitle,
    topline,
    editorialImageSlideshowImages,
    venuePdfForDownload,
    /*id,*/
  } = data.craftEntryInterface;
  const myColor = "var(--purple)";
  // console.log(id);
  const textColor = "var(--white)";
  const backgroundColor = "var(--black)";
  // console.log(editorialImageSlideshowImages);
  // console.log(headerImage);
  // console.log(topline);
  return (
    <Layout
      footerColor={myColor}
      backgroundColor={backgroundColor}
      headerColor={textColor}
      SEOIsArticle
      title={title}
      SEODescription={editorialShortDescription}
      SEOImageURL={
        headerImage &&
        headerImage.length &&
        headerImage[0].SEOImage &&
        headerImage[0].SEOImage.childImageSharp
          ? `https://alserkal.online${headerImage[0].SEOImage.childImageSharp.fixed.src}`
          : null
      }
    >
      <div>
        <ArticleHeroImage
          topline={`${topline || ""}`}
          secondline={`${editorialDate ? getDate(editorialDate) : null}`}
          titleImage={
            headerImage.length ? (
              <Img fixed={headerImage[0].slide.childImageSharp.fixed} />
            ) : (
              <div className="placeholder" />
            )
          }
          titleText={title}
          subTitleText={subtitle || editorialAuthor}
          color={"var(--purple)"}
          useCarousel
          carouselImages={
            editorialImageSlideshowImages.length
              ? editorialImageSlideshowImages.map(
                  (x) => x.slide.childImageSharp
                )
              : headerImage.length
              ? [headerImage[0].slide.childImageSharp]
              : []
          }
        />
        <ImageBody
          title={title}
          author={editorialAuthor}
          description={editorialShortDescription}
        />
        {editorialIsPhotoEssay ? (
          <PhotoEssayDescriptionBlock
            author={editorialPhotoEssayAuthor}
            date={editorialDate}
            description={editorialPhotoEssayDescription}
          />
        ) : null}
        {editorialIsPhotoEssay ? (
          <PhotoEssayWrapper>
            <ArticleBody
              mainColumn={richContent}
              color={myColor}
              textColor={"var(--black)" || textColor}
              hideSocial
              leftColumn={
                venuePdfForDownload.length && venuePdfForDownload[0].url ? (
                  <a href={venuePdfForDownload[0].url} download>
                    <SmallButton>
                      <Download color={"var(--color)"} />
                      Download PDF pack
                    </SmallButton>
                  </a>
                ) : null
              }
            />
          </PhotoEssayWrapper>
        ) : null}
        <ArticleFilter
          backgroundColor={"var(--black)"}
          defaultType={"images"}
        />
      </div>
    </Layout>
  );
};

export default ArticleImagesPageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    craftEntryInterface(id: { eq: $id }) {
      ... on Craft_articles_images_Entry {
        title
        id
        editorialImageSlideshowImages {
          ... on Craft_editorialAssets_Asset {
            slide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 820, height: 600, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        editorialAuthor
        editorialShortDescription
        editorialIsPhotoEssay
        editorialPhotoEssayAuthor
        editorialPhotoEssayDescription
        editorialDate
        subtitle
        topline
        venuePdfForDownload {
          url
        }
        headerImage {
          ... on Craft_editorialAssets_Asset {
            SEOImage: localFile {
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
            slide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 820, height: 600, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        headerImageMobile{ 
          ... on Craft_editorialAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 550) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        richContent {
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            spotifyUrl
            typeHandle
          }

          ... on Craft_richContent_image_BlockType {
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            typeHandle
            images {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialVideo_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
  }
`;
