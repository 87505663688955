import React from "react";
import { H5SmallMedium, H3Strong } from "./../Typography";
import { getDate } from "./../../modules/time";
import { PhotoEssayDescriptionBlockDiv } from "./elements";

const PhotoEssayDescriptionBlock = ({ author, date, description }) => (
  <PhotoEssayDescriptionBlockDiv>
    <H5SmallMedium>
      Photo essay | {author} | {getDate(date)}
    </H5SmallMedium>
    <H3Strong>{description}</H3Strong>
  </PhotoEssayDescriptionBlockDiv>
);
export default PhotoEssayDescriptionBlock;
